<template>
    <div class="toastItem_wrapper"
         :class="data.type">
        <div class="toastItem_data">
            {{data.text}}
        </div>
        <div class="timeline" :style="{
          width: width,
          transition: `width ${data.time}ms linear`
        }"></div>
    </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import {isSameType} from "../../utils/helpers";
  export default {
    name: "ToastElement",
    data() {
      return {
        width: '100%',
        timerId: null,
      }
    },
    props: {
      data: {
        type:  Object,
        validator(propsData){
          const data_props = {
            time: Number,
            text: String,
            type:  String,
          }
          return Object.entries(data_props).every(([propName, expectedType]) => {
            return isSameType(propsData[propName]?.constructor, expectedType)
          })
        }
      }
    },
    methods:{
      ...mapMutations(["REMOVE_TOAST"]),
      removeToast(){
        clearInterval(this.timerId)
        this.REMOVE_TOAST(this.data)
      }
    },

    mounted() {
      window.requestAnimationFrame(() => (this.width = 0));
      this.timerId = setTimeout(this.removeToast, this.data.time)
    },
    beforeDestroy() {
      this.removeToast()
    }

  }
</script>

<style lang="scss" scoped>
    .toastItem_wrapper{
        background: rgba(200,200,200,1);
        min-width: 14rem;
        width: 30vw;
        max-width: 30rem;
        border-radius: 5px;
        overflow: hidden;
    }
    .toastItem_data{
        margin: 1rem 2rem;
    }

    .info{
        background: rgb(178, 202, 222);
        .timeline {
            background: rgb(100, 162, 215);
        }
    }

    .error{
        background: rgb(222, 178, 190);
        .timeline {
            background: rgb(106, 14, 38);
        }
    }

    .success{
        background: rgb(181, 255, 174);
        .timeline {
            background: rgb(60, 206, 45);
        }
    }

    .timeline {
        width: 100%;
        height: 5px;
        transition: width .2s linear;
    }
</style>