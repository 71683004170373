<template>
    <div class="toast-wrapper">
        <toast-element v-for="toast in  toastList"
                       :data="toast"
                       :key="toast.id"
        />
    </div>
</template>

<script>
  import ToastElement from "./ToastElement";
  import {mapState} from "vuex";
  export default {
    name: "ToastComponent",
    components: {ToastElement},
    computed: {
      ...mapState({
        toastList: state => state.settings.toastList
      }),
    }
  }
</script>

<style  lang="scss" scoped>
    .toast-wrapper{
        position: fixed;
        bottom: 0;
        right: 0;
        max-height: 100vh;
        padding: 2rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
</style>